<template>
  <span ref="mathJaxEl">{{formula}}</span>
</template>

<script>
export default {
  props: {
    formula: {
      type: String,
      default () {
        return ''
      }
    },
    safe: {
      type: Boolean,
      default: true
    },
    options: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  watch: {
    formula () {
      this.renderMathJax()
    }
  },
  mounted () {
    this.renderMathJax()
  },
  methods: {
    renderContent () {
      if (this.safe) {
        this.$refs.mathJaxEl.textContent = this.formula
      } else {
        this.$refs.mathJaxEl.innerHTML = this.formula
      }
    },

    renderMathJax () {
      this.renderContent()
      if (window.MathJax) {
        window.MathJax.Hub.Config({
          messageStyle: 'none',
          tex2jax: {
            inlineMath: [['$', '$'], ['\\(', '\\)']]
          },
          'HTML-CSS': { linebreaks: { automatic: true } },
          CommonHTML: { linebreaks: { automatic: true } },
          ...this.options
        })
        window.MathJax.Hub.Queue([
          'Typeset',
          window.MathJax.Hub,
          this.$refs.mathJaxEl
        ])
      }
    }
  }
}
</script>
